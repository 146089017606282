import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  NoDocumentsFeedbackReq,
  OVChatGPTInput,
  XFChatGPTInput,
  XPChatGPTInput
} from '../../model/ChatGPT/search/ChatGPT';
import {FeedbackReq} from '../../model/ChatGPT/FeedbackReq';
import {OVDocumentInput, XFDocumentInput, XPDocumentInput} from '../../model/ChatGPT/documents/documentSearch';
import {DeleteItemSubscribe, SubscribeItemListReq, SubscribeItemReq} from "../../model/item-subscribe/subscribe";

type NewType = XFChatGPTInput;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {
  }

  getDateHeader() {
    return new HttpHeaders({
      'Initial-time': new Date().toISOString()
    });
  }

  getUrl(endpoint: string) {
    return environment.url + endpoint;
  }

  testAPI() {
    return this.http.get(this.getUrl("getSessionId"), {headers: this.getDateHeader()});
  }

  validateVinVisAPI(req: any, source: string) {
    return this.http.post(this.getUrl('validateVinVis/' + source), req, {headers: this.getDateHeader()});
  }

  askDTA(req: XFChatGPTInput | XPChatGPTInput | OVChatGPTInput, source: string) {
    return this.http.post(this.getUrl('askDTA/' + source), req, {headers: this.getDateHeader()});
  }

  jwtValidate(source: String, jwts: (String | null)[]) {
    return this.http.post(this.getUrl('jwtValidate/' + source), jwts, {headers: this.getDateHeader()});
  }

  saveDocumentFeedback(req: FeedbackReq) {
    return this.http.post(this.getUrl('saveFeedback'), req, {headers: this.getDateHeader()});
  }

  documentSearch(req: XFDocumentInput | XPDocumentInput | OVDocumentInput) {
    return this.http.post(this.getUrl('documentSearch'), req);
  }

  subscribeItem(req: SubscribeItemReq) {
    return this.http.post(this.getUrl('subscribeItem'), req);
  }

  getSubscribedList(req: SubscribeItemListReq) {
    return this.http.post(this.getUrl('getSubscribedList'), req);
  }

  deleteSubscribedItems(req: DeleteItemSubscribe) {
    return this.http.post(this.getUrl('deleteItemSubscribe'), req);
  }

  getSubscribedItemsNumber(userId: string) {
    return this.http.get(this.getUrl("getSubscribedItemsNumber/" + userId));
  }

  resetSubscribedItemsCounter(userId: string) {
    return this.http.get(this.getUrl('resetSubscribedItemsCounter/' + userId));
  }

  getItemsNumber(userId: string) {
    return this.http.get(this.getUrl("getItemsNumber/" + userId));
  }

  noDocumentFeedback(req: NoDocumentsFeedbackReq) {
    return this.http.post(this.getUrl('noDocumentFeedback'), req);
  }
}
