import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateScore'
})
export class TruncateScorePipe implements PipeTransform {

  transform(value: number): string {
    return Math.trunc(value * 100) + '%';
  }

}