import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
/**
 * Error component is a popup that is displayed on all windows when error occurs.
 * This element contains the error code,description,time and stack trace of the error.
 */
@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements OnInit {
  public statusCode!: string;
  public url!: string;
  public message!: string;
  public details!: string;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.matIconRegistry.addSvgIcon(
      'cross_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/cross_icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'info_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/info_icon.svg'
      )
    );
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeydown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  /**
   When page opens this function excutes and assign the values to particulr variables and displays properly on page.
   */
  ngOnInit(): void {
    this.statusCode = this.data.statusCode;
    this.url = this.data.url;
    this.details = this.data.details;
    this.message = this.data.message;
  }

  /**
   Function to copy the error details into clipboard.
 */
  copyText() {
    this.clipboard.copy(
      this.statusCode + ' ' + this.url + ' ' + this.details
    );
  }
}