<div class="page">
  <div class="container-fluid">
    <div class="row m-4">
      <div class="col">
        <h2 class="ms-3"> My items in search of solution </h2>
      </div>
    </div>
  </div>
  <div class="row m-4">
    <app-form *ngIf="formGroup" [searchSpinner]="searchSpinner"
              (submitEvent)="askDTA()"
              [formGroup]="formGroup"
              [formCustomize]="formCustomize"
              [hasVinBeenValidated]="true"
    ></app-form>
  </div>
  @if (noDocuments && (formGroup.get('vinWithoutValidation')!.value != '' || formGroup.get('itemNumber')!.value != '')) {
    <mat-error class="p-3"> No results found, please try again
    </mat-error>
  }
  @if (allDocuments) {
    <div class="row m-4">
      <app-item-subscription-results [documents]="allDocuments.documents"></app-item-subscription-results>
    </div>
  }
</div>
