import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vin-popup',
  templateUrl: './vin-popup.component.html',
  styleUrl: './vin-popup.component.scss'
})
export class VinPopupComponent {
  possibleVINs!: string[];
  selectedVIN: string | null = null;
  vin!: string;

  constructor(
    public dialogRef: MatDialogRef<VinPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { possibleVINs: string[], vin: string }
  ) {
    this.possibleVINs = data.possibleVINs;
    this.vin = data.vin;
  }

  selectVIN(vin: string): void {
    this.selectedVIN = vin;
  }

  confirmSelection(): void {
    this.dialogRef.close(this.selectedVIN);
  }

}
