import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VinPopupComponent } from '../../library/vin-popup/vin-popup.component';
import {
  brandCodeMap,
  ChatGPTOutput,
  Config,
  Document,
  NoDocumentsFeedbackReq,
  OVChatGPTInput,
  ProblemData,
  XFChatGPTInput,
  XPChatGPTInput,
} from '../../model/ChatGPT/search/ChatGPT';
import { ValidateReq } from '../../model/CinAPI/ValidateReq';
import { xFValidationOutput } from '../../model/CinAPI/xF/xFValidationOutput';
import { xPValidationOutput } from '../../model/CinAPI/xP/xPValidationOutput';
import { FormCustomize } from '../../model/Form/FormCustomize';
import { ErrorPopupComponent } from '../../popup/error-popup/error-popup.component';
import { ApiService } from '../../services/api/api.service';
import { homepageForm } from './homepage-form';
import { isVINValidated } from '../../library/form/form.component';
import { FeedbackPopupComponent } from '../../popup/feedback-popup/feedback-popup.component';

const SESSION_ID_KEY = 'sessionId';
const CONVERSATION_ID_KEY = 'conversationId';
const JWT_KEY = 'jwt';

const availableLanguages = [
  'fr-FR',
  'fr-CA',
  'it-IT',
  'es-ES',
  'en-US',
  'zh-CN',
  'de-DE',
  'pt-PT',
  'nl-NL',
  'ar-SA',
  'bg-BG',
  'hr-HR',
  'cs-CZ',
  'da-DK',
  'fi-FI',
  'el-GR',
  'he-IL',
  'hu-HU',
  'ja-JP',
  'nb-NO',
  'pl-PL',
  'ro-RO',
  'ru-RU',
  'sr-RS',
  'sk-SK',
  'sl-SI',
  'sv-SE',
  'tr-TR',
  'uk-UA',
  'en-GB',
];

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent implements OnInit {
  public formCustomize: FormCustomize[] = homepageForm;
  public allDocuments!: ChatGPTOutput | null;
  public bestDocument!: Document | null;
  public otherDocuments!: Document[] | null;
  public visibleDocuments!: Document[] | null;
  private VIN!: string;
  private source!: string;
  private token!: string;
  private tokenParam!: string;
  public formGroup!: FormGroup;
  public problemMaxLength: number = 800;
  public hasVinBeenValidated: boolean = false;
  public validateSpinner: boolean = false;
  public searchSpinner: boolean = false;
  private userRole!: string;
  public previousVin!: string;
  public userId!: string;
  public vinData!: xFValidationOutput | xPValidationOutput | undefined;
  private callBackUrl: string = '';
  private dealerLanguage: any;
  public noDocuments: boolean = false;
  private brandList = [];
  public feedback: boolean | null = null;
  public disableFeedback: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private dialog: MatDialog,
    private router: Router,
    public translate: TranslateService
  ) {
    availableLanguages.forEach((lang) => {
      this.translate.getTranslation(lang).subscribe();
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      const tokenParam = params['tokenParam'];
      const source = params['Source'];

      sessionStorage.setItem('jwt', token);

      if (source) {
        this.source = source;
      }
      if (token) {
        this.token = token;
      }
      if (source == 'LinkEntry' && tokenParam) {
        this.tokenParam = tokenParam;
        sessionStorage.setItem('tokenParam', tokenParam);
      }

      if (!source || !token || (source == 'LinkEntry' && !tokenParam)) {
        this.router.navigateByUrl('/error');
        return;
      }

      this.api
        .jwtValidate(this.source, [
          this.token,
          this.source == 'LinkEntry' || this.source == 'POI-XP'
            ? tokenParam
            : null,
        ])
        .subscribe(
          (response: any) => {
            switch (this.source) {
              case 'Hub':
                this.VIN = response.vin;
                this.userId = response.userId;
                this.callBackUrl = response.fdzCallbackURL;
                this.dealerLanguage = response.language;
                this.brandList = response.brandList;
                this.userRole = response.userRole;
                break;
              case 'LinkEntry':
                this.VIN = response.VIN;
                this.userId = response.UserID;
                this.dealerLanguage = response.Language;
                this.userRole = response.userRole;
                break;
              case 'POI-XP':
                this.VIN = response.vin;
                this.userId = response.userID;
                this.dealerLanguage = response.language;
                this.callBackUrl = response.callBackUrl;
                this.userRole = response.userRole;
                break;
            }

            if (sessionStorage.getItem('language'))
              this.translate.use(sessionStorage.getItem('language')!);
            else if (this.dealerLanguage) {
              const formattedDealerLanguage = this.dealerLanguage.replace(
                '_',
                '-'
              );

              if (availableLanguages.includes(formattedDealerLanguage)) {
                this.translate.use(formattedDealerLanguage);
              } else {
                this.translate.use('en-GB');
              }
            }

            if (!sessionStorage.getItem('sessionId'))
              sessionStorage.setItem('sessionId', response.sessionId);
            sessionStorage.setItem('userId', this.userId);
            this.initializeForm();
            this.getSubscribedItemsNumber();
          },
          (error: any) => {
            this.router.navigateByUrl('/error');
          }
        );
    });
  }

  getSubscribedItemsNumber() {
    this.api.getSubscribedItemsNumber(this.userId).subscribe((resp: any) => {
      sessionStorage.setItem('itemsSubscribed', JSON.stringify(resp.items));
    });
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      problemNature: ['Vehicular'],
      vin: [
        this.VIN ?? '',
        [Validators.required, isVINValidated(this.hasVinBeenValidated)],
      ],
      model: [{ value: null, disabled: true }],
      dtcValues: [[]],
      problem: [
        '',
        [Validators.required, Validators.maxLength(this.problemMaxLength)],
      ],
    });
    if (this.VIN) {
      this.validateVINVIS();
    }

    this.formGroup.get('vin')?.valueChanges.subscribe((change: any) => {
      if (change !== this.previousVin) {
        this.validateSpinner = false;
        this.previousVin = change;
        this.hasVinBeenValidated = false;
        this.vinData = undefined;
        this.formGroup.get('model')?.patchValue(null);
        this.formGroup.get('vin')?.updateValueAndValidity();
      }
      this.previousVin = change;
    });

    this.formGroup.get('problemNature')?.patchValue('Vehicular');
  }

  validateVINVIS() {
    this.validateSpinner = true;
    let vinController = this.formGroup.get('vin');

    let req: ValidateReq = {
      vin: vinController?.value.length === 17 ? vinController?.value : null,
      vis: vinController?.value.length === 8 ? vinController?.value : null,
      dealerLanguage: this.translate.currentLang.replace('-', '_'),
    };

    if (req.vin) this.validateVIN(req);
    else if (req.vis) this.validateVIS(req);
    else {
      this.validateSpinner = false;
    }
  }

  validateVIN(req: ValidateReq): void {
    let vinController = this.formGroup.get('vin');

    this.api.validateVinVisAPI(req, 'Hub').subscribe(
      (respHub: any) => {
        if (respHub && respHub.length === 1) {
          this.formGroup.patchValue({ vin: respHub[0].vin });
          this.formGroup.patchValue({
            model:
              respHub[0].pcdData[0]?.brand +
              ' ' +
              respHub[0].pcdData[0]?.ESAModelDescription +
              ' ' +
              respHub[0].pcdData[0]?.ESAModelYear +
              ' ' +
              respHub[0].pcdData[0]?.engineDescription,
          });
          this.previousVin = respHub[0].vin;
          this.vinData = respHub[0];
          vinController?.setErrors(null);
          this.hasVinBeenValidated = true;
          this.validateSpinner = false;
        } else {
          callHubApi();
        }
      },
      (errorLink: any) => {
        callHubApi();
      }
    );

    const callHubApi = () => {
      this.api.validateVinVisAPI(req, 'LinkEntry').subscribe(
        (respLink: any) => {
          if (
            respLink &&
            respLink.exfcaData &&
            respLink.exfcaData.length === 1
          ) {
            this.formGroup.patchValue({
              vin: respLink.exfcaData[0]?.chassisData.vin,
            });
            this.formGroup.patchValue({
              model: respLink.exfcaData[0]?.vehicleDescription.carline,
            });
            this.previousVin = respLink.exfcaData[0]?.chassisData.vin;
            this.vinData = respLink;
            vinController?.setErrors(null);
            this.hasVinBeenValidated = true;
            this.validateSpinner = false;
          }
        },
        (errorHub: any) => {
          this.validateSpinner = false;
          this.hasVinBeenValidated = false;
          this.formGroup.get('vin')?.updateValueAndValidity();

          const currentErrors = this.formGroup.get('vin')?.errors || {};
          const newErrors = { ...currentErrors, notAvailable: true };
          this.formGroup.get('vin')?.setErrors(newErrors);
        }
      );
    };
  }

  validateVIS(req: ValidateReq): void {
    let multipleVin: string[] = [];

    this.api.validateVinVisAPI(req, 'Hub').subscribe(
      (respHub: any) => {
        if (respHub && respHub.length !== 0 && respHub.length !== 1) {
          const vins = respHub
            .map((element: any) => element.vin)
            .filter((vin: string) => vin !== '');
          vins.forEach((vin: string) => {
            multipleVin.push(vin);
          });
        } else if (respHub && respHub.length === 1)
          multipleVin.push(respHub[0].vin);
        callHubApi();
      },
      (errorLink: any) => {
        callHubApi();
      }
    );

    const callHubApi = () => {
      this.api.validateVinVisAPI(req, 'LinkEntry').subscribe(
        (respLink: any) => {
          if (
            respLink &&
            respLink.exfcaData.length !== 0 &&
            respLink.exfcaData.length !== 1
          ) {
            const vins = respLink.exfcaData
              .map((element: any) => element.vin)
              .filter((vin: string) => vin !== '');
            vins.forEach((vin: string) => {
              multipleVin.push(vin);
            });
          } else if (respLink && respLink.exfcaData.length === 1)
            multipleVin.push(respLink.exfcaData[0]?.chassisData.vin);

          if (multipleVin.length == 1) {
            this.formGroup.patchValue({ vin: multipleVin[0] });
            this.validateVINVIS();
          } else if (multipleVin.length != 0) this.openVinPopup(multipleVin);
          else {
            this.validateSpinner = false;
            this.hasVinBeenValidated = false;
            this.formGroup.get('vin')?.updateValueAndValidity();
            const currentErrors = this.formGroup.get('vin')?.errors || {};
            const newErrors = { ...currentErrors, notAvailable: true };
            this.formGroup.get('vin')?.setErrors(newErrors);
          }
        },
        (errorHub: any) => {
          if (multipleVin.length == 1) {
            this.formGroup.patchValue({ vin: multipleVin[0] });
            this.validateVINVIS();
          } else if (multipleVin.length != 0) this.openVinPopup(multipleVin);
          else {
            this.validateSpinner = false;
            this.hasVinBeenValidated = false;
            this.formGroup.get('vin')?.updateValueAndValidity();
            const currentErrors = this.formGroup.get('vin')?.errors || {};
            const newErrors = { ...currentErrors, notAvailable: true };
            this.formGroup.get('vin')?.setErrors(newErrors);
          }
        }
      );
    };
  }

  openVinPopup(possibleVINs: string[]) {
    this.validateSpinner = false;
    this.hasVinBeenValidated = false;
    this.formGroup.get('vin')?.updateValueAndValidity();

    const dialogRef = this.dialog.open(VinPopupComponent, {
      width: '450px',
      disableClose: true,
      data: {
        possibleVINs: possibleVINs,
        vin: this.formGroup.get('vin')?.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.previousVin = result;
        this.formGroup.get('vin')?.patchValue(result);
        this.VIN = result;
        this.validateVINVIS();
      } else {
        this.hasVinBeenValidated = false;
        this.formGroup.get('vin')?.updateValueAndValidity();
      }
    });
  }

  private getSessionItem(key: string): string {
    return sessionStorage.getItem(key) ?? '';
  }

  // Helper method to create config object
  private createConfig(): Config {
    return {
      sessionId: this.getSessionItem(SESSION_ID_KEY),
      conversationId: this.getSessionItem(CONVERSATION_ID_KEY),
      language_iso_code: this.translate.currentLang,
      portalType: this.source == 'POI-XP' ? 'Hub' : this.source,
      userRole: this.userRole,
    };
  }

  // Helper method to create problem data
  private createProblemData(event: any): ProblemData {
    const problemNature = this.formGroup.get('problemNature')?.value;
    const problemNatureMap: Record<string, string> = {
      Document: 'DOC',
      Portal: 'SB',
    };

    return {
      problem: event.problem,
      problemNature: problemNatureMap[problemNature] ?? problemNature,
      vin: event.vin ?? '',
      model:
        this.formGroup.get('model')?.value != null
          ? this.formGroup.get('model')?.value
          : '',
      DTCs: event.dtcValues,
      brand: this.getBrandList(),
    };
  }

  // Helper method to get brand list
  private getBrandList(): string[] {
    if (!this.vinData) return [];

    if (this.source === 'Hub') return this.brandList;

    if ('exfcaData' in this.vinData && this.vinData.exfcaData) {
      return [this.vinData.exfcaData[0].vehicleDescription.brandCode];
    }

    return [];
  }

  // Helper method to determine endpoint
  private determineEndpoint(
    req: XFChatGPTInput | XPChatGPTInput | OVChatGPTInput
  ): string {
    if (req.problemData.problemNature !== 'Vehicular') {
      return 'notVehicular';
    }

    // Check for XP or OV by looking at specific properties
    if ('applicability' in req && 'PCDAPVPR' in req.applicability) {
      return 'xP';
    }

    if (
      'applicability' in req &&
      'lcdv24' in req.applicability &&
      !('PCDAPVPR' in req.applicability)
    ) {
      return 'OVLegacy';
    }

    return 'xF';
  }

  // Helper method to handle API response
  private handleApiResponse(resp: ChatGPTOutput): void {
    this.noDocuments = resp.documents.length === 0;
    this.feedback = null;
    this.allDocuments = resp;

    const documents = resp.documents;
    documents.forEach((doc) => (doc.feedback = null));

    this.bestDocument = documents[0];
    this.otherDocuments = documents.slice(1);
    this.visibleDocuments = this.otherDocuments.slice(0, 2);

    sessionStorage.setItem(CONVERSATION_ID_KEY, resp.conversationId);
    this.searchSpinner = false;
  }

  // Helper method to handle API error
  private handleApiError(err: any): void {
    const error = {
      statusCode: err.error.statusCode,
      url: err.error.url.replace('uri=/aiengine/api/', ''),
      details: err.error.details,
      message: 'Error with ChatGpt api',
    };

    this.errorPopup(error);
    this.resetDocuments();
    this.searchSpinner = false;
    this.noDocuments = true;
  }

  // Helper method to reset documents
  private resetDocuments(): void {
    this.allDocuments = null;
    this.bestDocument = null;
    this.otherDocuments = null;
    this.visibleDocuments = null;
  }

  // Main method to ask DTA
  askDTA(event: any): void {
    this.noDocuments = false;

    const config = this.createConfig();
    const problemData = this.createProblemData(event);
    const req = this.createRequest(config, problemData);

    if (!req) {
      console.error('Request configuration is not defined.');
      return;
    }

    this.searchSpinner = true;
    const endpoint = this.determineEndpoint(req);

    console.log(req);

    this.api.askDTA(req, endpoint).subscribe(
      (resp: any) => this.handleApiResponse(resp),
      (err: any) => this.handleApiError(err)
    );
  }

  private createRequest(
    config: Config,
    problemData: ProblemData
  ): XFChatGPTInput | XPChatGPTInput | OVChatGPTInput | undefined {
    if (!this.vinData) {
      return this.createDefaultRequest(config, problemData);
    }

    if ('pcdData' in this.vinData) {
      return this.vinData.isOVLegacy
        ? this.createOVRequest(config, problemData)
        : this.createXPRequest(config, problemData);
    }

    if ('exfcaData' in this.vinData) {
      return this.createXFRequest(config, problemData);
    }

    return undefined;
  }

  // Helper method to create default request
  private createDefaultRequest(
    config: Config,
    problemData: ProblemData
  ): XPChatGPTInput {
    return {
      config,
      problemData,
      applicability: {
        itemToken: this.getSessionItem(JWT_KEY),
        lcdv24: null,
        modelYear: null,
        listLcdvOvip: null,
        PCDAPVPR: null,
      },
      linkToDocument: null,
      userId: this.userId,
    };
  }

  // Helper method to create XP request
  private createXPRequest(
    config: Config,
    problemData: ProblemData
  ): XPChatGPTInput {
    const pcdData = (this.vinData as xPValidationOutput).pcdData[0];

    return {
      config,
      problemData,
      applicability: {
        lcdv24: pcdData.lcdv24?.slice(0, 4) ?? null,
        listLcdvOvip: this.getListLcdvOvip() ?? null,
        modelYear: Number(pcdData.vehicleBuildDate.slice(0, 4)) ?? null,
        itemToken: this.getSessionItem(JWT_KEY),
        PCDAPVPR: pcdData.PCDAPVPR ?? null,
      },
      linkToDocument: this.callBackUrl,
      userId: this.userId,
    };
  }

  // Helper method to create OV request
  private createOVRequest(
    config: Config,
    problemData: ProblemData
  ): OVChatGPTInput {
    const pcdData = (this.vinData as xPValidationOutput).pcdData[0];

    return {
      config,
      problemData,
      applicability: {
        lcdv24: pcdData.lcdv24?.slice(0, 4) ?? null,
        itemToken: this.getSessionItem(JWT_KEY),
      },
    };
  }

  // Helper method to create XF request
  private createXFRequest(
    config: Config,
    problemData: ProblemData
  ): XFChatGPTInput {
    const exfcaData = (this.vinData as xFValidationOutput).exfcaData[0];
    const vehicleDescription = exfcaData.vehicleDescription;

    return {
      config,
      problemData,
      applicability: {
        mvs: vehicleDescription.mvs ?? null,
        brandcode: brandCodeMap.get(vehicleDescription.brandCode)!,
        itemToken: this.getSessionItem(JWT_KEY),
        modelYear:
          Number(vehicleDescription.vehicleBuildDate.slice(-4)) ?? null,
      },
      userId: this.userId,
    };
  }

  errorPopup(error: any) {
    this.dialog.open(ErrorPopupComponent, {
      disableClose: true,
      data: error,
    });
  }

  getListLcdvOvip() {
    let lcdv24 = (this.vinData as xPValidationOutput).pcdData[0].lcdv24;
    let filteredList: string[] = [];

    (this.vinData as xPValidationOutput).pcdData[0].listLcdvOvip.forEach(
      (item) => {
        const firstFiveDigits = item.trim().substring(0, 5);
        filteredList.push(firstFiveDigits);
      }
    );

    if (lcdv24) {
      filteredList.push('B0A' + '0' + lcdv24.slice(0, 1));
      filteredList.push('B0B' + '0' + lcdv24.slice(1, 2));
      filteredList.push('B0C' + lcdv24.slice(2, 4));
      filteredList.push('B0D' + lcdv24.slice(4, 6));
      filteredList.push('B0E' + '0' + lcdv24.slice(6, 7));
      filteredList.push('B0F' + lcdv24.slice(7, 9));
      filteredList.push('B0G' + '0' + lcdv24.slice(9, 10));
      filteredList.push('B0H' + lcdv24.slice(10, 12));
      filteredList.push('B0J' + lcdv24.slice(12, 14));
      filteredList.push('B0K' + '0' + lcdv24.slice(14, 15));
      filteredList.push('B0L' + '0' + lcdv24.slice(15, 16));
      filteredList.push('B0M' + lcdv24.slice(16, 18));
      filteredList.push('B0N' + lcdv24.slice(18, 20));
      filteredList.push('B0P' + lcdv24.slice(20, 22));
      filteredList.push('B0R' + lcdv24.slice(22, 24));
    }

    return filteredList;
  }

  openFeedbackPopup() {
    let dialog = this.dialog.open(FeedbackPopupComponent, {
      width: '400px',
      data: { document: null, searchHistory: this.allDocuments?.searchHistory },
    });

    dialog.afterClosed().subscribe((reason: string) => {
      this.saveFeedback(reason);
    });
  }

  clearFormAndResults() {
    this.formGroup.reset({
      problemNature: this.formGroup.get('problemNature')?.value,
      vin: '',
      model: null,
      dtcValues: [],
      problem: '',
    });
    this.resetDocuments();
  }

  showMoreDocuments() {
    this.visibleDocuments = this.otherDocuments!.slice(0, 4);
  }

  setFeedback(value: boolean) {
    this.disableFeedback = true;
    if (this.feedback === value) {
      this.feedback = null;
    } else {
      this.feedback = value;
    }
    if (this.feedback !== false) {
      this.saveFeedback();
    } else if (this.feedback === false) {
      this.openFeedbackPopup();
    }
  }

  saveFeedback(reason?: string) {
    let req: NoDocumentsFeedbackReq = {
      searchHistory: this.allDocuments?.searchHistory!,
      feedback: this.feedback,
      feedbackReason: reason ?? '',
    };
    this.api.noDocumentFeedback(req).subscribe(
      (resp: any) => {
        this.disableFeedback = false;
      },
      (error: any) => (this.disableFeedback = false)
    );
  }
}
