<div class="row">
  <div class="col-6">
    <button [disabled]="disableFeedback" mat-button (click)="setFeedback(true)" [style.opacity]="document.feedback === true ? 1 : 0.4">
      <mat-icon>thumb_up</mat-icon>
    </button>
  </div>
  <div class="col-6">
    <button [disabled]="disableFeedback" mat-button (click)="setFeedback(false)" [style.opacity]="document.feedback === false ? 1 : 0.4">
      <mat-icon>thumb_down</mat-icon>
    </button>
  </div>
</div>