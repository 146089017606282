import {FormCustomize} from "../../model/Form/FormCustomize";

export let documentSearchForm: FormCustomize[] = [
  {
    propertyName: "documentType",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-6",
    selectValues: [
      'Technical Service Bulletin [Service Box]',
      'Service News Technical Service Bulletin [eLearn]',
      'Teseo Bullettin [eLearn]',
      'Item [iSolve]',
      'DTC [eLearn]'
    ]
  },
  {
    propertyName: "documentReference",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-6",
  },
  {
    propertyName: "vin",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-6"
  },
  {
    propertyName: "model",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-6"
  },
  {
    propertyName: "search",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-12 justify-content-center align-items-center"
  },
];
