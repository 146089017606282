import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-vin',
  templateUrl: './document-vin.component.html',
  styleUrl: './document-vin.component.scss'
})
export class DocumentVinComponent {
  public vin: string = "";

  constructor(
    public dialogRef: MatDialogRef<DocumentVinComponent>
  ) {
  }

  confirmSelection() {
    this.dialogRef.close(this.vin);
  }
}
