<div class="container profile">
  <div class="row d-flex align-items-center">
    <div class="col-auto">
      <h6>{{ userId }}</h6>
    </div>
    <div class="col-auto">
      <mat-icon class="icon">account_circle</mat-icon>
    </div>
  </div>
</div>
