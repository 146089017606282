import {FormCustomize} from "../../model/Form/FormCustomize";

export let homepageForm: FormCustomize[] = [
  {
    propertyName: "problemNature",
    style: "col-4",
    selectValues: [
      'Vehicular',
      'WITECH',
      'DIAGBOX',
      'Document',
      'Portal'
    ]
  },
  {
    propertyName: "empty",
    style: "col-8",
  },
  {
    propertyName: "vin",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-4",
    isPrefixVisible: true
  },
  {
    propertyName: "model",
    style: "col-sm-12	col-md-12	col-lg-12 col-xl-4"
  },
  {
    propertyName: "dtcValues",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-4 text dtc"
  },
  {
    propertyName: "problem",
    style: "col-sm-12 col-md-12	col-lg-12 col-xl-12"
  },
  {
    propertyName: "search",
    style: "col-sm-6 col-md-6	col-lg-6 col-xl-6 justify-content-end align-items-center"
  },
  {
    propertyName: "clear",
    style: "col-sm-6 col-md-6	col-lg-6 col-xl-6 justify-content-start align-items-center"
  },
];
