import { RouterModule, Routes } from '@angular/router';
import { TestPageComponent } from './pages/test/test-page.component';
import { NgModule } from '@angular/core';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { DocumentSearchComponent } from './pages/document-search/document-search.component';
import {ItemSubscriptionComponent} from "./pages/item-subscription/item-subscription.component";

export const routes: Routes = [
  {
    path: 'digitaltechassistance/request/search', component: HomepageComponent, data: {
      queryParams: ['Source', 'token', 'tokenParam'],
    }
  },
  {
    path: 'digitaltechassistance/request/document', component: DocumentSearchComponent, data: {
      queryParams: ['Source', 'token', 'tokenParam'],
    }
  },
  {
    path: 'digitaltechassistance/request/item-subscription', component: ItemSubscriptionComponent, data: {
      queryParams: ['Source', 'token', 'tokenParam'],
    }
  },
  { path: 'error', component: ErrorPageComponent },

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
