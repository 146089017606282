<div class="page">
  <div class="container-fluid" *ngIf="formGroup">
    <div class="row m-4">
      <div class="col-12">
        <app-form *ngIf="formGroup" [validateSpinner]="validateSpinner" [searchSpinner]="searchSpinner"
          [hasVinBeenValidated]="hasVinBeenValidated" [dtcLimit]="10" (submitEvent)="askDTA($event)"
          (apiEvent)="validateVINVIS()" [formGroup]="formGroup" [problemMaxLength]="1600"
          [formCustomize]="formCustomize"></app-form>
      </div>
    </div>
    @if (noDocuments) {
    <div class="row">
      <div class="col">
        <mat-error class="p-3"> {{ 'noDocuments'|translate }}
        </mat-error>
      </div>
      <div class="col feedback" style="text-align: end !important;">
        <p class="p-3">Leave a feedback
          <button [disabled]="disableFeedback" mat-button (click)="setFeedback(true)"
            [style.opacity]="feedback === true ? 1 : 0.4">
            <mat-icon style="color: #243782 !important; margin-right:10px; margin-left:10px;">thumb_up
            </mat-icon>
          </button>
          <button [disabled]="disableFeedback" mat-button (click)="setFeedback(false)"
            [style.opacity]="feedback === false ? 1 : 0.4">
            <mat-icon style="color: #243782 !important; margin-right:10px; margin-left:10px;">thumb_down
            </mat-icon>
          </button>
        </p>
      </div>
    </div>
    }
    @if (otherDocuments && bestDocument) {
    <div class="row m-4">
      <h2 class="ms-3">{{ 'bestDocument'|translate }}</h2>
      <app-document-card [searchHistory]="allDocuments!.searchHistory" [hasFeedback]="true"
        class="best-document ms-3 me-3" [document]="bestDocument" [isBestAnswer]="true"
        [vin]="formGroup.get('vin')?.value" [vinType]="vinData && vinData.hasOwnProperty('pcdData') ? 'xP' : 'xF'"
        [itemType]="formGroup.get('problemNature')?.value"></app-document-card>
    </div>
    <div class="row m-4">
      <h2 class="ms-3">{{ 'otherDocuments'|translate }}</h2>
      @for (document of visibleDocuments; track document) {
      <app-document-card [searchHistory]="allDocuments!.searchHistory" [hasFeedback]="true" class="ms-3 me-3"
        [document]="document" [isBestAnswer]="false" [vin]="formGroup.get('vin')?.value"
        [vinType]="vinData && vinData.hasOwnProperty('pcdData') ? 'xP' : 'xF'"
        [itemType]="formGroup.get('problemNature')?.value"></app-document-card>
      }
    </div>
    <div class="row m-4 d-flex justify-content-center more-details-button">
      <p class="p-4">
        <b> *{{ 'documentWarning'|translate }} </b>
      </p>
      @if (visibleDocuments!.length == 4 || otherDocuments!.length == 0 || visibleDocuments!.length ==
      otherDocuments!.length) {
      <mat-error class="ps-5 p-3">{{ 'noAdditionalDocuments'|translate }}
      </mat-error>
      }
      <button mat-button class="col-auto p-3"
        [disabled]="visibleDocuments!.length == 4 || otherDocuments!.length == 0 || visibleDocuments!.length == otherDocuments!.length"
        (click)="showMoreDocuments()">
        {{ 'loadMoreResults'|translate }}
      </button>
    </div>
    }
  </div>
</div>