export interface XFDocumentInput {
  config: Config;
  documentData: DocumentData;
  applicability: XFApplicability;
  userId: string;
  vinType: string;
}

export interface OVDocumentInput {
  config: Config;
  documentData: DocumentData;
  applicability: OVApplicability;
  userId: string;
  linkToDocument: string | null;
  vinType: string;
}

export interface XPDocumentInput {
  config: Config;
  documentData: DocumentData;
  applicability: XPApplicability;
  userId: string;
  linkToDocument: string | null;
  vinType: string;
}

export interface XFApplicability {
  mvs: string | null;
  brandcode: string | null;
  modelYear: number | null;
  itemToken: string;
}

export interface OVApplicability {
  itemToken: string;
  lcdv24: string | null;
}

export interface XPApplicability {
  itemToken: string;
  lcdv24: string | null;
  listLcdvOvip: string[] | null;
  modelYear: number | null;
  PCDAPVPR: string | null;
}

export interface DocumentData {
  documentType: string;
  documentReference: string;
  vin: string;
  model: string;
  brand: string[]
}

export interface Config {
  sessionId: string | null;
  conversationId: string | null;
  language_iso_code: string;
  portalType: string;
  userRole: string;
}

export interface ChatGPTOutput {
  documents: Document[];
  sessionId: string;
  conversationId: string;
  searchHistory: number;
}

export interface Document {
  title: string;
  url: string;
  score: number;
  summary: string;
  customerSymptoms: string;
  type: string;
  feedback: boolean | null;
  item_solution_type: string;
  item_solution_type_translated: string;
}

export const brandCodeMap: Map<string, string> = new Map([
  ["CY", "55"],
  ["DG", "56"],
  ["JE", "57"],
  ["RM", "58"],
  ["AR", "83"],
  ["LA", "70"],
  ["AH", "66"],
  ["FO", "77"],
  ["FT", "00"]
]);
