import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ApiService} from "./services/api/api.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'dta-ui';
  selectedIndex: number = 0;
  itemsSubscribed: number = 0;
  private navigatingProgrammatically = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ApiService
  ) {
    let userId = sessionStorage.getItem('userId');
    if (userId) {
      this.service.getItemsNumber(userId).subscribe((resp: any) => {
        this.itemsSubscribed = resp.items.length;
        sessionStorage.setItem('itemsUpdated', JSON.stringify(resp));
      });
    }
    setInterval(() => {
      let userId = sessionStorage.getItem('userId');
      if (userId) {
        this.service.getItemsNumber(userId).subscribe((resp: any) => {
          this.itemsSubscribed = resp.items.length;
          sessionStorage.setItem('itemsUpdated', JSON.stringify(resp));
        });
      }
    }, 600000);
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && !this.navigatingProgrammatically) {
        const url = event.urlAfterRedirects;

        if (url.includes('search')) {
          this.selectedIndex = 0;
        } else if (url.includes('document')) {
          this.selectedIndex = 1;
        } else if (url.includes('item-subscription')) {
          this.selectedIndex = 2;
          this.itemsSubscribed = 0;
        } else if (url.includes('saved-searches')) {
          this.selectedIndex = 3;
        } else if (url.includes('saved-documents')) {
          this.selectedIndex = 4;
        }
        this.navigatingProgrammatically = false;
      }
    });
  }

  onTabChange(index: number): void {
    if (this.selectedIndex !== index) {
      this.navigatingProgrammatically = true;

      const queryParams = this.route.snapshot.queryParams;

      if (index === 0) {
        this.selectedIndex = 0;
        this.router.navigate(['digitaltechassistance/request/search'], {queryParams});
      } else if (index === 1) {
        this.selectedIndex = 1;
        this.router.navigate(['digitaltechassistance/request/document'], {queryParams});
      } else if (index === 2) {
        this.selectedIndex = 2;
        this.router.navigate(['digitaltechassistance/request/item-subscription'], {queryParams});
      } else if (index === 3) {
        this.selectedIndex = 3;
        this.router.navigate(['digitaltechassistance/request/saved-searches'], {queryParams});
      } else if (index === 4) {
        this.selectedIndex = 4;
        this.router.navigate(['digitaltechassistance/request/saved-documents'], {queryParams});
      }
    }
  }
}
