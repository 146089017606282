<mat-card [ngClass]="{ 'green-border': isBestAnswer }">
  <div class="row max-width pb-2 pt-3">
    <!-- titles -->
    <div class="col-2"><b>{{ 'documentType'|translate }}</b></div>
    <div class="col-1"><b>{{ 'documentTitle'|translate }}</b></div>
    <div class="col-1" *ngIf="document.type == 'Item - iSolve'"><b>Solution Type</b></div>
    <ng-container *ngIf="isBestAnswer; else notBestAnswer">
      <div [ngClass]="{'col-5':document.type != 'Item - iSolve', 'col-4':document.type == 'Item - iSolve'}">
        <b> {{ 'documentSymptoms'|translate }} </b></div>
    </ng-container>
    <ng-template #notBestAnswer>
      <div [ngClass]="
        document.type === 'Item - iSolve'
          ? (hasFeedback ? 'col-2' : 'col-3')
          : (hasFeedback ? 'col-3' : 'col-4')
      "><b> {{ 'documentSymptoms'|translate }} </b></div>
      <div [ngClass]="{'col-2': hasFeedback, 'col-3': !hasFeedback}"><b>{{ 'documentSolution'|translate }}*</b></div>
    </ng-template>
    @if (hasFeedback) {
      <div class="col-2"><b> {{ 'documentScore'|translate }} </b></div>
    }

    <ng-container>
      <div class="col-2">
        <button mat-raised-button class="button"
                *ngIf="document.item_solution_type === 'Solution in search' && document.type === 'Item - iSolve'"
                [disabled]="getSubscribedItemsNumber().includes(document.title) || loading" (click)="subscribeItem()">Subscribe
          ITEM
        </button>
      </div>
    </ng-container>
  </div>

  <div class="row max-width max-height">
    <!-- data -->
    <div class="col-2" [style.gridRow]="2">{{ document.type }}</div>
    <div class="col-1" [style.gridRow]="1">
      <a [href]="document.url" matTooltip="The url is not available" [matTooltipDisabled]="document.url != ''"
         [ngClass]="{'disabled':document.url == ''}" target="_blank">{{ document.title }}</a>
    </div>
    <div class="col-1" *ngIf="document.type == 'Item - iSolve'"
         [style.gridRow]="1">{{ document.item_solution_type_translated }}
    </div>

    @if (isBestAnswer) {
      <div [ngClass]="{'col-5':document.type != 'Item - iSolve', 'col-4':document.type == 'Item - iSolve'}"
           [style.gridRow]="2">
        <p class="truncate">{{ document.customerSymptoms }}</p>
      </div>
    } @else {
      <div [ngClass]="
        document.type === 'Item - iSolve'
          ? (hasFeedback ? 'col-2' : 'col-3')
          : (hasFeedback ? 'col-3' : 'col-4')
      " [style.grid-row]="'2'">
        <p class="truncate">{{ document.customerSymptoms }}</p>
      </div>
      <div [ngClass]="{'col-2': hasFeedback, 'col-3': !hasFeedback}" [style.grid-row]="'2'">
        <p class="truncate">{{ document.summary }}</p>
      </div>
    }

    @if (hasFeedback) {
      <div class="col-2 green-font" [style.gridRow]="2">
        <b>{{ document.score | truncateScore }}</b>
      </div>
    }

    <ng-container *ngIf="isBestAnswer">
      <div class="col-2" [style.gridRow]="2">
        <button mat-button (click)="openDocumentPopup()">
          <mat-icon svgIcon="info-icon" class="info-icon"></mat-icon>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="!isBestAnswer">
      <div class="col-1" [style.gridRow]="2" [style]="{hasFeedback : 'border-right: 1px solid grey'}">
        <button mat-button (click)="openDocumentPopup()">
          <mat-icon svgIcon="info-icon" class="info-icon"></mat-icon>
        </button>
      </div>
      @if (hasFeedback) {
        <div class="col-1" [style.gridRow]="2">
          <app-feedback [document]="document" [searchHistory]="searchHistory"></app-feedback>
        </div>
      }
    </ng-container>

  </div>
</mat-card>

<ng-container *ngIf="isBestAnswer">
  <div class="row solution-summary max-width">
    <div class="col-12"><b>{{ 'documentSolution'|translate }}*</b></div>
    <div class="col-12">
      <p style="white-space: pre-line; height: 10rem; overflow-y: auto;">{{ document.summary }}</p>
    </div>
    <div class="col">
      <a [href]="document.url" matTooltip="The url is not available" [matTooltipDisabled]="document.url != ''"
         [ngClass]="{'disabled':document.url == ''}"
         target="_blank"><b>{{ 'documentView'|translate }}</b>
        <mat-icon>link</mat-icon>
      </a>
    </div>
    <div class="col d-flex justify-content-end">
      <app-feedback [document]="document" [searchHistory]="searchHistory"></app-feedback>
    </div>
  </div>
  <hr/>
</ng-container>
