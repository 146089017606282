<div class="m-3">
  <div class="row">
    <div class="col-1">
      <h2> {{"results"|translate}} </h2>
    </div>
    <div class="col">
      <button mat-raised-button class="button ms-5 me-5" (click)="selectAll()"> {{"selectAll"|translate}} </button>
      <button mat-raised-button class="button ms-5 me-5" [disabled]="!areAllDocumentsSelected()"
        (click)="deleteSelected()">{{"delete"|translate}}
      </button>
    </div>
  </div>
  <div *ngFor="let document of paginateDocuments">
    <div class="row">
      <mat-card
        [ngStyle]="{ 'border-bottom': itemsUpdated.items.includes(document.itemNumber) ? '5px solid #243782' : '' }">
        <div class="row max-width pb-2 pt-3">
          <!-- titles -->
          <div class="col-1"></div>
          <div class="col-2"><b> {{"subscriptionDate"|translate}} </b></div>
          <div class="col-2"><b>VIN/VIS</b></div>
          <div class="col-2"><b> {{"itemNumber"|translate}} </b></div>
          <div class="col-3"><b> {{"documentSymptoms"|translate}} </b></div>
          <div class="col-2"><b> {{"solutionType"|translate}} </b></div>
        </div>

        <div class="row max-width max-height">
          <!-- data -->
          <div class="col-1" [style.gridRow]="1">
            <mat-checkbox color="primary" [(ngModel)]="document.selected"></mat-checkbox>
          </div>
          <div class="col-2" [style.gridRow]="2">{{ document.subscriptionDate | date:'dd-MM-yyyy HH:mm' }}</div>
          <div class="col-2" [style.gridRow]="2">{{ document.vinVis }}</div>
          <div class="col-2" [style.gridRow]="2">
            <a [href]="document.url" matTooltip="The url is not available" [matTooltipDisabled]="document.url != ''"
              [ngClass]="{'disabled':document.url == ''}" target="_blank">{{ document.itemNumber }}</a>
          </div>
          <div class="col-3" [style.gridRow]="2">{{ document.customerSymptoms }}</div>
          <div class="col-2" [style.gridRow]="3">{{ document.solutionType }}</div>
        </div>
      </mat-card>
    </div>
  </div>

  <!-- Paginator -->
  <mat-paginator [hidePageSize]="true" [length]="documents.length" [pageSize]="5" (page)="pageChanged($event)">
  </mat-paginator>
</div>