<div class="container-fluid popup-container p-3">
    <div class="h5 mt-2 d-flex flex-column align-items-end">
        <label mat-dialog-close>X</label>
    </div>
    <div class="row d-flex justify-content-center text-center">
        <div class="col-12 h6 error-text">
            <label>ERROR</label>
        </div>
        <div class="h2">
            <label> {{statusCode}} </label>&nbsp;
            <label class="title"> {{url}} </label>
        </div>
        <div class="col-12 h6 error-text mt-2">
            <label style="word-break: break-word;">{{message}}</label>
        </div>
    </div>
    <div class="mt-4 button-container">
        <button mat-button class="col-5 similarity-button" mat-dialog-close>CLOSE</button>
        <button mat-button class="col-6 copy-button d-none d-sm-block" (click)="copyText()"><span
                class="material-icons">content_copy</span> COPY ERROR CODE </button>
    </div>
</div>