<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{'language'| translate}}</mat-label>
    <mat-icon matPrefix>language</mat-icon>
    <input type="text" placeholder="Pick one" aria-label="Language" matInput [formControl]="language"
      [matAutocomplete]="auto" class="hide-values">
    <mat-autocomplete (opened)="resetFilter()" (closed)="checkIfEmpty()" (optionSelected)="changeLanguage($event)" autoActiveFirstOption
      #auto="matAutocomplete">
      @for (option of filteredOptions | async; track option) {
      <mat-option [value]="option">{{option | translate}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>