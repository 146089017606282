import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Document } from '../../model/ChatGPT/search/ChatGPT';
import { SubscribeItemReq } from '../../model/item-subscribe/subscribe';
import { DocumentPopupComponent } from '../../popup/document-popup/document-popup.component';
import { DocumentVinComponent } from '../../popup/document-vin/document-vin.component';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrl: './document-card.component.scss',
})
export class DocumentCardComponent {
  @Input() document!: Document;
  @Input() isBestAnswer!: boolean;
  @Input() searchHistory!: number;
  @Input() hasFeedback: boolean = true;
  @Input() vin!: string;
  @Input() vinType!: string;
  @Input() itemType!: string;
  @Output() vinOutput = new EventEmitter<string>();
  loading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private service: ApiService
  ) {
    this.matIconRegistry.addSvgIcon(
      `info-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../assets/icons/info-icon.svg'
      )
    );
  }

  openDocumentPopup() {
    let dialog = this.dialog.open(DocumentPopupComponent, {
      width: '800px',
      data: {
        document: this.document,
        searchHistory: this.searchHistory,
        hasFeedback: this.hasFeedback,
        vin: this.vin,
        vinType: this.vinType,
        itemType: this.itemType,
      },
    });
    dialog.afterClosed().subscribe(event => {
      if(event != null && event != '')
        this.vinOutput.emit(event);
    });
  }

  openVinPopup() {
    if (!this.hasFeedback && !this.vin) {
      let dialog = this.dialog.open(DocumentVinComponent, {
        width: '600px',
      });

      dialog.afterClosed().subscribe((event: any) => {
        if (event != undefined && event != '') {
          this.vinOutput.emit(event);
        }
      });
    }
  }

  subscribeItem() {
    this.loading = true;
    let req: SubscribeItemReq = {
      itemNumber: this.document.title,
      sessionId: sessionStorage.getItem('sessionId')!,
      userId: sessionStorage.getItem('userId')!,
      itemType: this.itemType,
      solutionType: this.document.item_solution_type,
      vin: this.vin,
      customerSymptoms: this.document.customerSymptoms,
      vinType: this.vinType,
      url: this.document.url,
    };

    this.service.subscribeItem(req).subscribe(
      (resp: any) => {
        this.getUpdatedSubscribedItemsNumbers();
        this.loading = false;
      },
      (error) => (this.loading = false)
    );
  }

  getSubscribedItemsNumber(): string[] {
    return JSON.parse(sessionStorage.getItem('itemsSubscribed')!);
  }

  getUpdatedSubscribedItemsNumbers() {
    this.service
      .getSubscribedItemsNumber(sessionStorage.getItem('userId')!)
      .subscribe((resp: any) => {
        sessionStorage.setItem('itemsSubscribed', JSON.stringify(resp.items));
      });
  }
}
