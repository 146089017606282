<h6 class="d-flex justify-content-end" mat-dialog-close>
  <mat-icon>close</mat-icon>
</h6>
<h2 mat-dialog-title class="d-flex justify-content-center">{{'validateVin'|translate}}</h2>
<mat-dialog-content>
  <b> {{ vin }} </b> {{'vinPopup'|translate}}
  <div class="vin-list-container">
    <mat-button-toggle-group class="col-12" vertical>
      @for (v of possibleVINs; track v) {
      <mat-button-toggle (click)="selectVIN(v)" style="line-height: 1rem">
        {{ v.slice(0, 9) }}<b>{{ v.slice(9, 17) }}</b>
      </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button type="submit" class="col-auto d-flex justify-content-center align-items-center button"
    (click)="confirmSelection()" [disabled]="selectedVIN === null">
    {{'select'|translate}}
  </button>
</mat-dialog-actions>