<h6 class="d-flex justify-content-end" (click)="confirmSelection()">
    <mat-icon>close</mat-icon>
  </h6>
  <h2 mat-dialog-title class="d-flex justify-content-center">Please insert the VIN to open the document</h2>
  <mat-dialog-content class="p-2">
    <div class="vin-list-container">
      <mat-form-field appearance="outline" class="col-12">
        <input matInput [(ngModel)]="vin" matInput type="text">
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-center p-2">
    <button mat-raised-button type="submit" class="col-auto d-flex justify-content-center align-items-center button"
      (click)="confirmSelection()" [disabled]="vin.length != 8 && vin.length != 17">
      Validate
    </button>
  </mat-dialog-actions>