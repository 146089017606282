<app-header></app-header>
<div class="menu mt-3">
  <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="onTabChange($event)">
    <mat-tab class="ps-1 pe-1" [label]="'wiSolve' | translate"></mat-tab>
    <mat-tab class="ps-1 pe-1" [label]="'documentSearch'|translate"></mat-tab>
    <mat-tab class="ps-1 pe-1"
      [label]="('itemSubscription'|translate) + (itemsSubscribed != 0 ? '('+itemsSubscribed+')' : '')"></mat-tab>
    <!-- <mat-tab class="ps-1 pe-1" [disabled]="true" label="My Saved Searches"></mat-tab>
    <mat-tab class="ps-1 pe-1" [disabled]="true" label="My Saved Documents"></mat-tab> -->
  </mat-tab-group>
</div>
<router-outlet></router-outlet>
<app-footer></app-footer>