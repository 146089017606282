import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import {
  DeleteItemSubscribe,
  ItemDocuments,
} from '../../model/item-subscribe/subscribe';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-item-subscription-results',
  templateUrl: './item-subscription-results.component.html',
  styleUrl: './item-subscription-results.component.scss',
})
export class ItemSubscriptionResultsComponent implements OnChanges, OnInit {
  @Input() documents!: ItemDocuments[];
  paginateDocuments: ItemDocuments[] = [];
  pageSize = 5;
  currentPage = 0;
  itemsUpdated: { items: string[] } = { items: [] };

  constructor(private service: ApiService) {}

  ngOnInit() {
    this.updatePaginatedDocuments();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updatePaginatedDocuments();
  }

  updatePaginatedDocuments() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginateDocuments = this.documents.slice(startIndex, endIndex);
  }

  areAllDocumentsSelected() {
    return (
      this.documents.length > 0 &&
      this.documents.some((document) => document.selected)
    );
  }

  ngOnChanges(): void {
    if (
      sessionStorage.getItem('itemsUpdated') != null ||
      sessionStorage.getItem('itemsUpdated') != undefined
    )
      this.itemsUpdated = JSON.parse(sessionStorage.getItem('itemsUpdated')!);
  }

  selectAll() {
    this.documents.forEach((doc) => (doc.selected = true));
  }

  deleteSelected() {
    const selectedDocuments = this.documents.filter((doc) => doc.selected);
    if (selectedDocuments.length > 0) {
      let req: DeleteItemSubscribe = {
        userId: sessionStorage.getItem('userId')!,
        documents: selectedDocuments,
      };
      this.service.deleteSubscribedItems(req).subscribe((resp: any) => {
        this.getUpdatedSubscribedItemsNumbers();
        window.location.reload();
      });
    }
  }

  getUpdatedSubscribedItemsNumbers() {
    this.service
      .getSubscribedItemsNumber(sessionStorage.getItem('userId')!)
      .subscribe((resp: any) => {
        sessionStorage.setItem('itemsSubscribed', JSON.stringify(resp.items));
      });
  }
}
