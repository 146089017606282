import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';



@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrl: './language-selection.component.scss'
})
export class LanguageSelectionComponent implements OnInit {
  language = new FormControl('');
  options: string[] = [
    "fr-FR",
    "fr-CA",
    "it-IT",
    "es-ES",
    "en-GB",
    "en-US",
    "zh-CN",
    "de-DE",
    "pt-PT",
    "nl-NL",
    "ar-SA",
    "bg-BG",
    "hr-HR",
    "cs-CZ",
    "da-DK",
    "fi-FI",
    "el-GR",
    "he-IL",
    "hu-HU",
    "ja-JP",
    "nb-NO",
    "pl-PL",
    "ro-RO",
    "ru-RU",
    "sr-RS",
    "sk-SK",
    "sl-SI",
    "sv-SE",
    "tr-TR",
    "uk-UA"
  ];
  filteredOptions!: Observable<string[]>;

  constructor(
    public translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      sessionStorage.setItem('language', event.lang);
      this.setFormControllerValue();
    }
    );
  }

  setFormControllerValue() {
    this.translate.get(this.translate.currentLang).subscribe(translatedValue => this.language.setValue(translatedValue))
  }

  ngOnInit() {
    this.filteredOptions = this.language.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value || ''))
    );
  }

  private _filter(value: string): Observable<string[]> {
    const filterValue = value.toLowerCase();
    return this.translate.get(this.options).pipe(
      map((translations: { [key: string]: string }) => {
        return this.options.filter(option => translations[option].toLowerCase().includes(filterValue));
      })
    );
  }

  changeLanguage(event: MatAutocompleteSelectedEvent) {
    this.translate.use(event.option.value);
  }

  checkIfEmpty() {
    this.setFormControllerValue();
  }

  resetFilter() {
    this.language.setValue("");
  }
}
