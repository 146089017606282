import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrl: './test-page.component.scss',
})
export class TestPageComponent implements OnInit {
  public message: string = '';

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.testAPI().subscribe((res: any) => {
      this.message = res.test as string;
    });
  }
}
