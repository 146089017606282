import {LiveAnnouncer} from '@angular/cdk/a11y';
import {ENTER} from '@angular/cdk/keycodes';
import {Component, EventEmitter, Input, Output,} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators,} from '@angular/forms';
import {FormCustomize} from '../../model/Form/FormCustomize';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent {
  @Input() formCustomize!: FormCustomize[];
  @Input() problemNature!: string;
  @Input() public hasVinBeenValidated!: boolean;
  @Input() formGroup!: FormGroup;
  @Input() problemMaxLength!: number;
  @Input() dtcLimit!: number;
  @Input() validateSpinner: boolean = false;
  @Input() searchSpinner: boolean = false;
  @Output() apiEvent = new EventEmitter<any>();
  @Output() submitEvent = new EventEmitter<any>();
  @Output() selectChangesEvent = new EventEmitter<any>();
  readonly separatorKeysCodes = [ENTER] as const;
  readonly itemPattern: RegExp = /^[a-zA-Z0-9]{5}(?:[a-zA-Z0-9]{2}|\s[a-zA-Z0-9]{2}|-[a-zA-Z0-9]{2})?$/;

  constructor(private announcer: LiveAnnouncer, private fb: FormBuilder) {
  }

  remove(index: number): void {
    const dtcValuesControl = this.formGroup.get('dtcValues');
    const dtcValues = dtcValuesControl?.value as string[];
    const removedValue = dtcValues.splice(index, 1)[0];
    dtcValuesControl?.setValue(dtcValues);
    this.announcer.announce(`Removed ${removedValue}`);
  }

  setupInputValueChanges(): void {
    const dtcInputControl = this.fb.control('');

    dtcInputControl.valueChanges.subscribe((value) => {
      this.validateInput(value!);
    });

    this.formGroup.addControl('dtcInput', dtcInputControl);
  }

  validateInput(event: any | null): void {
    const value = event.target.value;

    this.formGroup.get('dtcValues')?.setErrors(null);

    if (value && value != '') {
      if (!this.itemPattern.test(value) && value.length <= 8)
        this.formGroup.get('dtcValues')?.setErrors({pattern: true});

      if (value.length > 8)
        this.formGroup.get('dtcValues')?.setErrors({maxlength: true});
    }
  }

  add(event: HTMLInputElement): void {
    if (
      this.itemPattern.test(event.value) &&
      event &&
      this.formGroup.get('dtcValues')!.value.length < this.dtcLimit
    ) {
      const dtcValuesControl = this.formGroup.get('dtcValues');
      const dtcValues = dtcValuesControl?.value as string[];
      dtcValues.push(event.value);
      dtcValuesControl?.setValue(dtcValues);
      event.value = '';
    }
  }

  addChipSet(event: MatChipInputEvent) {
    if (
      this.itemPattern.test(event.value) &&
      event &&
      this.formGroup.get('dtcValues')!.value.length < this.dtcLimit
    ) {
      const dtcValuesControl = this.formGroup.get('dtcValues');
      const dtcValues = dtcValuesControl?.value as string[];
      dtcValues.push(event.value);
      dtcValuesControl?.setValue(dtcValues);
      event.value = '';
      event.chipInput.clear();
    }
  }

  submitForm(): void {
    if (this.formGroup.valid) {
      this.submitEvent.emit(this.formGroup.value);
    }
  }

  validateVIN(): void {
    this.apiEvent.emit();
  }

  getStyle(propertyName: string) {
    return this.formCustomize.find(
      (element: FormCustomize) => element.propertyName === propertyName
    )?.style;
  }

  getSelectValues(propertyName: string) {
    return this.formCustomize.find(
      (element: FormCustomize) => element.propertyName === propertyName
    )?.selectValues;
  }

  isFilter(propertyName: string) {
    const element = this.formCustomize.find(
      (element: FormCustomize) => element.propertyName === propertyName
    );

    return element?.isFilter !== undefined ? element.isFilter : false;
  }

  isPrefixVisible(propertyName: string) {
    const element = this.formCustomize.find(
      (element: FormCustomize) => element.propertyName === propertyName
    );

    return element?.isPrefixVisible !== undefined ? element.isPrefixVisible : false;
  }

  onSelectionChange(): void {
    const vinControl = this.formGroup.get('vin');
    if (vinControl) {
      vinControl.clearValidators();
      if (this.formGroup.get('problemNature')?.value === 'Vehicular') {
        vinControl.setValidators([
          Validators.required,
          isVINValidated(this.hasVinBeenValidated),
        ]);
      } else {
        vinControl.setValidators([isVINValidated(this.hasVinBeenValidated)]);
      }
      vinControl.updateValueAndValidity();
    } else {
      console.error('vinControl is not found in the formGroup.');
    }
  }
}

export function isVINValidated(hasVinBeenValidated: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!hasVinBeenValidated && control.value != null && control.value !== '') {
      return {notValidated: true};
    }
    return null;
  };
}

export function numericValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = /^[0-9]{4,5}$/.test(control.value);
    return isValid ? null : {numericInvalid: true};
  };
}

export function alphanumericValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = /^[a-zA-Z0-9]{8,12}$/.test(control.value);
    return isValid ? null : {alphanumericInvalid: true};
  };
}

export function alphanumericSpecialValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = /^[a-zA-Z0-9.]{1,13}$/.test(control.value);
    return isValid ? null : {alphanumericSpecialInvalid: true};
  };
}

export function dtcValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid =
      /^[a-zA-Z0-9]{5}(?:[a-zA-Z0-9]{2}|\s[a-zA-Z0-9]{2}|-[a-zA-Z0-9]{2})?$/.test(
        control.value
      );
    return isValid ? null : {dtcInvalid: true};
  };
}
