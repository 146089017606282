<h6 class="d-flex justify-content-end" mat-dialog-close (click)="saveFeedback()">
  <mat-icon>close</mat-icon>
</h6>
<div style="overflow-x: hidden">
  <div class="row justify-content-center p-3">
    <h2 class="col-9 d-flex justify-content-end document-title" mat-dialog-title>
      {{ 'documentDetails'|translate }}
    </h2>
    <div class="col-3">
      <button mat-raised-button class="button"
        *ngIf="document.item_solution_type === 'Solution in search' && document.type === 'Item - iSolve'"
        [disabled]="getSubscribedItemsNumber().includes(document.title) || loading" (click)="subscribeItem()">
        {{"subscribeItem"|translate}}
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <div class="row pb-1">
      <div class="title" [ngClass]="{'col-3': hasFeedback, 'col-4':!hasFeedback}">{{ 'documentType'|translate }}</div>
      <div class="title" [ngClass]="{'col-3': hasFeedback, 'col-4':!hasFeedback}">{{ 'documentTitle'|translate }}</div>
      <div class="title" *ngIf="document.type == 'Item - iSolve'"
        [ngClass]="{'col-3': hasFeedback, 'col-4':!hasFeedback}"> {{"solutionType" | translate}} </div>
      <div class="col-3 title" *ngIf="hasFeedback">{{ 'documentScore'|translate }}</div>
    </div>
    <div class="row pb-3">
      <div [ngClass]="{'col-3': hasFeedback, 'col-4':!hasFeedback}">{{ document.type }}</div>
      <div [ngClass]="{'col-3': hasFeedback, 'col-4':!hasFeedback}" (click)="openVinPopup()">
        <a [href]="document.url" matTooltip="The url is not available" [matTooltipDisabled]="document.url != ''"
          [ngClass]="{'disabled':document.url == ''}" target="_blank">{{ document.title }}</a>
      </div>
      <div *ngIf="document.type == 'Item - iSolve'" [ngClass]="{'col-3': hasFeedback, 'col-4':!hasFeedback}">
        {{document.item_solution_type_translated}}</div>
      <div class="col-3 green-font" *ngIf="hasFeedback">{{ document.score | truncateScore }}</div>
    </div>
    <div class="row pb-1">
      <div class="col-12 title">{{ 'documentSymptoms'|translate }}</div>
    </div>
    <div class="row pb-3">
      <div class="col-12">{{ document.customerSymptoms }}</div>
    </div>
    <div class="row pb-1">
      <div class="col-12 title">{{ 'documentSolution'|translate }}*</div>
    </div>
    <div class="row pb-3">
      <div class="col-12">
        <p style="white-space: pre-line">{{ document.summary }}</p>
      </div>
    </div>
    <p class="small-font">
      {{ 'documentWarning'|translate }}
    </p>
    <div class="row pb-1 justify-content-between">
      <div class="col-10" (click)="openVinPopup()">
        <a [href]="document.url" matTooltip="The url is not available" [matTooltipDisabled]="document.url != ''"
          [ngClass]="{'disabled':document.url == ''}" target="_blank" rel="noopener noreferrer"><b>{{
            'documentView'|translate }}</b>
          <mat-icon>link</mat-icon>
        </a>
      </div>
      @if (hasFeedback) {
      <div class="col-2">
        <div class="row">
          <div class="col-6">
            <button [disabled]="disableFeedback" mat-button (click)="setFeedback(true)"
              [style.opacity]="document.feedback === true ? 1 : 0.4">
              <mat-icon>thumb_up</mat-icon>
            </button>
          </div>
          <div class="col-6">
            <button [disabled]="disableFeedback" mat-button (click)="setFeedback(false)"
              [style.opacity]="document.feedback === false ? 1 : 0.4">
              <mat-icon>thumb_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
      }
    </div>
    @if (document.feedback === false && hasFeedback) {
    <div class="row">
      <div class="col-12">
        <h6 class="pb-1 pt-1">{{ 'feedback'|translate }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="vin-list-container">
          <mat-form-field appearance="outline" class="col-12">
            <textarea matInput [(ngModel)]="feedbackReason"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    }
    <div class="row"></div>
  </mat-dialog-content>
  @if (document.feedback === false && hasFeedback) {
  <mat-dialog-actions class="feedback p-3">
    <div class="col-12 d-flex justify-content-center pb-1 pt-1">
      <button (click)="saveFeedback()" [disabled]="feedbackReason.length > 750" class="button" mat-button>{{
        'send'|translate }}
      </button>
    </div>
  </mat-dialog-actions>
  }
</div>