<div class="page">
  <div class="container-fluid">
    <div class="row m-4">
      <div class="col">
        <h2 class="ms-3"> Search for a specific document </h2>
      </div>
    </div>
  </div>
  <div class="row m-4">
    <app-form *ngIf="formGroup" [validateSpinner]="validateSpinner" [searchSpinner]="searchSpinner"
              [hasVinBeenValidated]="hasVinBeenValidated" [dtcLimit]="10" (submitEvent)="askDTA($event)"
              (apiEvent)="validateVINVIS()" [formGroup]="formGroup" [problemMaxLength]="1600"
              [formCustomize]="formCustomize"></app-form>
  </div>
  @if (noDocuments) {
    <mat-error class="p-3"> This document does not exist. Please try with another search.
    </mat-error>
  } @else {
    @if (otherDocuments) {
      <div class="row m-4">
        <h2 class="ms-3">Results</h2>
        @for (document of visibleDocuments; track document) {
          <app-document-card class="ms-3 me-3" [document]="document" [isBestAnswer]="false"
                             [hasFeedback]="false" [vin]="formGroup.get('vin')?.value"
                             [vinType]="vinData && vinData.hasOwnProperty('pcdData') ? 'xP' : 'xF'"
                             [itemType]="formGroup.get('documentType')?.value"></app-document-card>
        }
      </div>
      <div class="row m-4 d-flex justify-content-center">
        <button mat-button class="col-auto p-3" [disabled]="visibleDocuments!.length == otherDocuments!.length"
                (click)="showMoreDocuments()">
          {{ 'loadMoreResults'|translate }}
        </button>
      </div>
    }

    @if (singleDocument) {
      <div class="row m-4">
        <h2 class="ms-3">Results</h2>
        <app-document-card class="ms-3 me-3" [document]="singleDocument" [isBestAnswer]="false"
                           [hasFeedback]="false" [vin]="formGroup.get('vin')?.value"
                           [vinType]="vinData && vinData.hasOwnProperty('pcdData') ? 'xP' : 'xF'"
                           [itemType]="formGroup.get('documentType')?.value"></app-document-card>
      </div>
      <div class="row m-4 d-flex justify-content-center">
      </div>
    }
  }
</div>
